<template>
  <section id="buttons-preview" class="buttons-preview">
    <vx-tooltip
      class="buttons-preview-tooltip"
      v-if="!isLast"
      :text="lang.helpTooltips.botMaker.buttonsTab[languageSelected]"
      position="top"
    >
      <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" />
    </vx-tooltip>
    <div class="buttons-preview__wrapper" v-if="isSupportedBtnPlatform(msg)">
      <div class="name">{{ msg.type.name }}</div>
      <div class="buttons">
        <div
          v-for="(button, index) in msg.type.responseOptions"
          :key="index"
          class="option"
        >
          <span
            :style="{
              'text-decoration': button.type === 'url' ? 'underline' : 'unset'
            }"
          >
            {{ button.text }}
          </span>
        </div>
      </div>
    </div>
    <div v-else-if="!isSupportedBtnPlatform(msg)">
      <div class="name">{{ msg.type.name }}</div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { MESSAGE_TYPES_CHANNELS } from '../constants/constants'
export default {
  name: 'ButtonsPreview',
  props: {
    msg: Object,
    isLast: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', ['activeChannelView'])
  },
  methods: {
    isSupportedBtnPlatform(msg) {
      return MESSAGE_TYPES_CHANNELS[msg.type.getType()].includes(
        this.activeChannelView
      )
    }
  }
}
</script>

<style lang="scss">
.buttons-preview {
  display: flex;
  flex-direction: row;
  justify-content: center;

  &__wrapper {
    max-width: 230px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    .name {
      overflow: hidden;
      overflow-wrap: break-word;
      border-radius: 20px 20px 0 0;
      border: none;
      padding: 15px 15px 0 15px;
      background-color: #f0f0f0;
      white-space: pre-wrap;
      word-break: break-word;
      word-wrap: break-word;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      padding: 15px;
      background-color: #f0f0f0;
      border-radius: 0 0 20px 20px;

      .option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        border-radius: 5px;
        line-height: 1rem;
        background-color: white;
        color: black;

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }
      }
    }
  }
  position: relative;
  .buttons-preview-tooltip {
    position: absolute;
    right: -0.8rem;
    top: -0.8rem;
  }
}
</style>
